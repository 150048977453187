body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar{
  background-color: #007BFF;
  border-radius: 0px 0px 30px 30px;
  height: 88px;
  width: 661px;
  position: relative;
  z-index: 6;
  margin-left: 707px;
  margin-top: -481px;
}
.navbar li{
  float:left;
  list-style: none; 
  margin: 13px 20px;
  padding: 16px 32px;
  font-size:19px;
  font-weight: 600px;
  text-decoration: none;
  color: white;
}
.logo img {
  width: 104px; 
  height: 80px;
  margin-left:134px;
}
.two_person img{
  margin-left:865px;
  margin-top: 86px;
}
.btn-1{
  background-color: #007BFF;
  height: 56px;
    width: 122px;
    border: none;
    margin-left:175px;
    margin-top:-18px;
    border-radius:8px;
    font-size:15px;
    
}
table{
  width: 1053px;
    margin-left: 253px;
    margin-top: 75px;
    border-Collapse: separate;
    border-spacing:0px 13px;
}
.best-service{
  margin-top:-98px;
  height: 1100px;

}
.best-services {
font-size:22px;
  color:#007BFF;
  margin-left:184px;
}
.top{
  height: 10px;
  width: 485px;
  font-size: 30px;
  font-weight: 600;
  margin-left: 188px;
  margin-top: 17px;

}
.desc{
  width: 525px;
  font-size: 21px;
  font-weight: 500;
    margin-left: 820px;
    color:#757575;
}
.step{
  margin-top: 122px;
    height: 10px;
    width: 485px;
    font-size: 30px;
    font-weight: 600;
    margin-left: 95px;
}
.empower{
  height:20px;
  width:400px;
  margin-left:0px ;
  font-size: 20px;
    font-weight: 200;
  margin-top:0px;
}
.container{
  border-radius: 0px;
    opacity: 1;
    top: 25px;
    left: 0px;
    height: 681px;
    z-index: 5;
    margin-top:-390px;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: inherit;
}
.tainer{
  width: 468.55px;
    height: 177px;
    margin-left: 104px;
    margin-top: 97px;
    font-size: 21px;
    font-weight: 500;
    color:#757575;
    line-height: 1.5;
}
.main{
  margin-left: 48px;
  margin-top:-15px;
   
}
aside.girl{
  display: block;
  top: 190px;
   right: 75px;
    margin-right: 122px;
    height: auto;
    position: absolute; 
}
aside img{
  margin-left:0px;
  margin-top:-24px;
  
}
.con{
  height: 10px;
    width: 463px;
    font-size: 21px;
    font-weight: 600;
    font-family:'poppins';
    margin-left:112px;
    margin-top:103px;
}
.button{
  background-color: #007BFF;
  height: 64px;
    width: 165px;
    border: none;
    margin-left: 61px;
    margin-top: 28px;
    border-radius: 8px;
    font-size: 22px;
    font-weight:200;
    color:#fff; 
}
.service-item:hover {
transform:tra(10px , 10px);
transform:translate3d(40px,40px,40px);
box-shadow:30px -30px #007BFF;
border:none;
  
}
.services-list,
.services-list-2 {
  display: flex;
  gap: 50px;
  justify-content: center;
  margin-top: 162px;
}
.content2 {
  box-shadow: rgb(170, 170, 170) 0px 0px 15px 2px;
  background: -webkit-linear-gradient(top, var(rgba(255, 255, 255, 1)) 0%, rgba(133, 75, 248, 0.23) 100%);
  border-radius: 30px;
  margin-left: 225px;
  opacity: 1;
  top: 728px;
  left: 130px;
  width: 1080px;
  height: 228px;
  z-index: 48;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
  gap: 20px;
}

.img-container {
  margin-top:0px;
  border-radius: 50%;
  overflow: hidden;
  width: 100px; 
  height: 100px; 
  margin: 0 auto;
}
.services-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between; 
  gap: 20px;
}

.img-container {
  border-radius: 50%;
  overflow: hidden;
  width: 100px; 
  height: 100px; 
  margin: 0 auto;
}
.services-container {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 0px;
  margin-top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top:-162px;

}

.services-list {
  display: flex;
  flex-direction: column;
  gap: 79px;
  margin-top:243px;
}
.service-section {
  display: flex;
  gap: 130px;
  justify-content: center;
}
.service-item {
  box-shadow: rgb(170, 170, 170) 2px 2px 4px 0px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: rgb(170, 170, 170) 2px 2px 4px 0px;
    height: 294px;
    width: 259px;
    padding: 20px;
    border-radius: 30px 0px 30px 0px;
    text-align: center;
    margin: 10px;
    border: 2px solid #000;
    padding: 8px;
}
.img-container {
  box-shadow: rgb(170, 170, 170) 2px 2px 10px 0px;
  border-radius: 50%;
  overflow: hidden;
margin-left:-79px;
  -moz-border-radius: 0;
    opacity: 1;
    top: 1578px;
    left: 636px;
    z-index: 64;
    border:1px solid #000;
  border-radius: 50px;
  background-color: white; 
  display: flex;
  align-items: center;
  justify-content: center;
}
.s2{
  white-space: pre-wrap;
  overflow: visible;
  font-family: 'Montserrat';
  font-size: 25px;
  font-weight: 700;
  color: rgb(13, 9, 9);
  text-align: center;
  line-height: 1.4;
  border-radius: 0px;
  opacity: 1;
  width: 165px;
  height: 50px;
  z-index: 22;
  margin-top:-73px;
  margin-left:49px;
}
.blog-text1{
  margin-top:55px;
  color:#757575;
}
.background-container{
  background-image: url('./Components/assests/images/img_2.png');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  margin-top:-867px;
  height:1146px;
}
.meet{
  height: 10px;
  width: 284px;
  font-size: 30px;
  font-weight: 600;
  margin-left: 285px;
  margin-top: 114px;
}
.meet2{
  width: 395px;
  font-size: 21px;
  font-weight: 500;
    margin-left: 820px;
    color:#757575;
}
.guidence-container{
  background-color: #007BFF;
  height:313px;
  width:1518px;
  margin-top:108px;
}

/* Content */
.adv-container{
  display:flex;
  height:275px;
 margin-left:61px;
 padding:69px;
}
.adv-item{
  background-color: rgba(255, 255, 255, 1);
  box-shadow: rgb(170, 170, 170) 2px 2px 4px 0px;
  height: 161px;
    width: 255px;
  margin-top: 0px;
  border-radius: 30px;
  text-align: center;
  margin: 29px;
}
.adv-item:hover{
  border-radius:30px ;
  clip-path: polygon(
    0% 0%,
    23.31% 23.31%,
    35.64% 35.02%,
    52.03% 39.51%,
    68.42% 35.02%,
    80.75% 23.31%,
    100% 0%,
    100% 100%,
    0% 100%);
}

.img4-container{
  box-shadow: rgb(170, 170, 170) 2px 2px 4px 0px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -50px;
    -moz-border-radius: 0;
    opacity: 1;
    top: 1578px;
    left: 636px;
    z-index: 64;
    width:97px;
    height:96px;
    margin-left:83px;
    margin-bottom:28px;
 
  background-color: white; 
  display: flex;
  align-items: center;
  justify-content: center;
}
.img4-img{
  margin-left:0px;
  border-radius:50px;
}
.adv-title{
  white-space: pre-wrap;
    overflow: visible;
    font-family: 'poppins';
    font-size: 20px;
    font-weight: 700;
    color: #000;
    line-height: 1.4;
    border-radius: 0;
    -moz-border-radius: 0;
    opacity: 1;
    top: 105px;
    left: 46px;
    width: 265px;
    height: 23px;
    z-index: 41
}
.adv-desc{
  font-family:'poppins';
  border-radius: 50%;
  overflow: hidden;
  margin-top: 6px;
    -moz-border-radius: 0;
    opacity: 1;
    top: 1578px;
    left: 636px;
    z-index: 64;
  border-radius: 50px;
  background-color: white; 
  display: flex;
  align-items: center;
  font-size:16px;
  justify-content: center;
}

/* Supporter */
.best-services2 {
  font-size:22px;
    color:#007BFF;
    margin-left:184px;
  }
  .top2{
    height: 10px;
    width: 485px;
    font-size: 30px;
    font-weight: 600;
    margin-left: 188px;
    margin-top: 17px;
  
  }
  .desc2{
    width: 525px;
    font-size: 21px;
    font-weight: 500;
      margin-left: 820px;
      color:#757575;
  }
  .desc-container{
    margin-top:94px;
  }
  .img-container{
    height:100px;
    width:100px;
  }

.circle-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    transition: transform 0.5s ease;
  }
  
  .circle.active {
    transform: scale(1.2);
  }
   
  .circle img {
    width: 80%;
    height: auto;
  } 

  .blue-border-box {
    width: 600px; 
    height: 600px; 
    border: 1px solid blue; 
    border-radius:50%;
    margin-left:440px;
    margin-top:134px;
  }
  .blue-border-box-2{
    width: 530px;
    height: 530px;
    border: 1px solid blue;
    border-radius:50%;
    margin-left:34px;
    margin-top:34px;
  }
  .blue-border-box-3 {
    width: 460px;
    height: 460px;
    border: 1px solid blue; 
    border-radius:50%;
    margin-left:34px;
    margin-top:34px;
  }
  .blue-border-box-4 {
    width: 390px; 
    height: 390px;
    border: 1px solid blue; 
    border-radius:50%;
    margin-left:34px;
    margin-top: 34px;
  }
  .blue-border-box-5 {
    width: 320px; 
    height: 320px; 
    border: 1px solid blue; 
    border-radius:50%;
    margin-left:34px;
    margin-top:34px;
  }
.img11{
  margin-top: -14px;
  margin-left: 310px;
}
.img12{
  margin-top: -59px;
    margin-left: -145px;
}
.img13{
  margin-top: 92px;
    margin-left: 332px;
}
.img14{
  margin-top: -70px;
  margin-left: -133px;
}
.img15{
  margin-top:68px;
  margin-left:0px;
}
.img16{
  margin-top: -78px;
  margin-left: 258px;
}
.img17{
  margin-top: -141px;
  margin-left: 191px;
}
.img18{
  margin-left: 34px;
  margin-top: -95px;
}

.ready-container{
  background-color: #007BFF;
height:235px;
width:1080px;
margin-top:198px;
margin-left:227px;
border-radius:30px;
}
.ready{
 
    font-size: 37px;
    padding-top: 19px;
    margin-bottom: 30px;
    margin-left: 225px;
    white-space: pre-wrap;
    overflow: visible;
    font-family: 'montserrat';
    font-weight: 600;
    color: rgba(255,255,255,1);
    line-height: 2.4;
    opacity: 1;
    width: 682.999939px;
    margin-top: 0px;

}
.btn-class{
  background-color: #007BFF;
  font-size: 15px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  text-align: center;
  border-color: #fff;
  line-height: 1;
  border-radius: 11px;
  padding: 15px 24px;
  opacity: 1;
 box-shadow:#fff;
  cursor: pointer;
  top: 507px;
  left: 459px;
  width: 120px;
  height: 45px;
  z-index: 47;
  margin-left: -129px;
}
.ready-input{
  width: 501px;
    height: 61px;
  font-size: large;
  border-radius: 5px;
  box-shadow: rgb(170, 170, 170) 2px 2px 30px -5px;
    border-style: solid;
 
    font-family: 'poppins';
    margin-left: 298px;
    margin-top: -9px;
   
    border-width: 0px;
    border-color: rgba(9,23,71,0.15);
    border-radius: 10px;
    -moz-border-radius: 10px;
    
    font-size: 16px;
    font-weight: 400;
    color: #8b8b8b;
    padding: 6px;
    opacity: 1;
    top: 3760px;
    left: 670px;
   
    z-index: 70;
}
.back-container{
  background-image: url('./Components/assests/images/img_3.png');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  height: 300px;
    width: 1134px;
    margin-left: 184px;
    margin-top: -248px;
}

.footer-container{
  background-color:#007BFF;
  height: 543px;
  width: 1519px;
}
.footer-in-container{
  background-color:rgb(94, 171, 253);
  height: 445px;
  width: 1442px;
  border-radius:0px 0px 179px 0px;
}
.logo-2 img{
    width: 104px; 
    height: 80px;
    margin-left:134px;
    margin-top:50px;
  }
.foot-content{
  color:#fff;
  width:271px;
  height:100px;
  margin-left:126px;
}
.contactus{
  font-weight: bold;
    font-size: 28px;
    margin-top:-159px;
    font-family:'poppins';
    margin-left:525px;
}
.mobile{
  margin-left: 45px;
    margin-top: -27px;
    color: #fff;
    /* font-size: 18px; */
}
.cmail{
  margin-left: 47px;
    margin-top: -28px;
    color: #fff;
    font-family:'email';
    /* font-size: 21px; */
}
.footer-mail{
  margin-left: 526px;
  margin-top: 14px;
}
.footer-call{
  margin-left: 526px;
  margin-top: 23px;
}
.footer-mail img{
  height:30px;
  width:30px;
}
.footer-call img{
  height:30px;
  width:30px;
}
.useful-links{
  font-weight: bold;
    font-size: 28px;
    margin-top: -117px;
    font-family: 'poppins';
    margin-left: 791px;
}
.us-1{
  margin-left:794px;
  margin-top:14px;
  color:#fff;

}
.us-2{
  margin-left:794px;
  margin-top:12px;
  color:#fff;
  
}
.us-3{
  margin-left:794px;
  margin-top:11px;
  color:#fff;
  
}
.us-4{
  margin-left:794px;
  margin-top:10px;
  color:#fff;
  
}
.footer-con{
  font-weight: bold;
    font-size: 28px;
    margin-top: -149px;
    font-family: 'poppins';
    margin-left: 1060px;
}
.footer-email{
  background-color:rgb(94, 171, 253);
  box-shadow:#fff;
  margin-left:1060px;
  margin-top:16px;
  height:41px;
  width:254px;
}
.mail-footer{
  background-color:#fff;
  height:40px;
  width: 40px;
  border-radius:10px;
  margin-left:0px;
  margin-top:0px;
}
.footer-mail img{
  margin-top:0px;
  margin-bottom:0px;
  padding:0px;
}
.join-us{
  font-weight: bold;
    font-size: 28px;
    font-family: 'poppins';
    margin-left: 1059px;
}
.all-5{
  margin-left:1043px;
 padding:0px;
  display:flex;
}
.all-5 img{
  height: 40px;
  width: 40px;
  margin-left: 14px;
  margin-top: 35px;
}
.surat-location{
  margin-left:120px;
  margin-top:-156px;
  
}
.surat-location img{
  height:30px;
  width:30px;
 
}
.surat-add{
  margin-left: 156px;
    margin-top:-32px;
  font-weight: bold;
    font-size: 25px;
    font-family: 'poppins';
}
.loc {
  color:#fff;
  height:10px;
  width: 255px;
    margin-left: 118px;
    margin-top: 12px;
}
.ahmbd-location{
  margin-left: 535px;
  margin-top: -52px ;
}
.ahmbd-location img{
  height:30px;
  width:30px;
 margin-left:-53px;
}
.ahmbd-add{
  margin-left: 516px;
    margin-top:-32px;
  font-weight: bold;
    font-size: 25px;
    font-family: 'poppins';
}
.loc-1 {
  color:#fff;
  height:10px;
  width: 255px;
    margin-left: 485px;
    margin-top: 12px;
}

.patna-location{
  margin-left: 948px;
  margin-top: -52px ;
}
.patna-location img{
  height:30px;
  width:30px;
 margin-left:-107px;
}
.patna-add{
  margin-left: 873px;
    margin-top:-32px;
  font-weight: bold;
    font-size: 25px;
    font-family: 'poppins';
}
.loc-2 {
  color:#fff;
  height:10px;
  width: 255px;
    margin-left: 844px;
    margin-top: 12px;
}
.jaypur-location{
  margin-left: 948px;
  margin-top: -52px ;
}
.jaypur-location img{
  height:30px;
  width:30px;
 margin-left:194px;
}
.jaypur-add{
  margin-left: 1174px;
    margin-top:-32px;
  font-weight: bold;
    font-size: 25px;
    font-family: 'poppins';
}
.loc-3 {
  color:#fff;
  height:10px;
  width: 255px;
    margin-left: 1147px;
    margin-top: 12px;
}

/* support */
.gtu-container img{
  height:70px;
  width:108px;
  margin-left:0px;
  margin-top:0px;
}
.icpatna-container img{
  height: 89px;
    width: 108px;
    margin-left: 224px;
    margin-top: -85px;
}
.iim-container img{
  height: 48px;
    width: 108px;
    margin-left: -125px;
    margin-top: 113px;
}
.metalogo-container img{
  height: 70px;
  width: 108px;
  margin-left: 423px;
  margin-top: -52px;
}
.microsoftstartup-container img{
  height: 57px;
    width: 104px;
    margin-left: -116px;
    margin-top: 75px;
}
.ministry-container img{
  height: 70px;
    width: 108px;
    margin-left: 222px;
    margin-top: -54px;
}
.nvi-container img{
  height: 70px;
    width: 108px;
    margin-left: 400px ;
    margin-top: -80px;
}
.openai-container img{
  height: 70px;
  width: 116px;
  margin-left: -49px;
  margin-top: 351px;
}
.zohostartup-container img{
  height: 70px;
  width: 108px;
  margin-left: 440px;
  margin-top: -59px;
}

.metalogo-container{
  margin-left:-85px;
}
.navbar li{
  font-size:18px;
  font-weight: bold;
}
.navbar li:hover {
  background-color: white;
  color:#007BFF;
  border-radius:10px;
}

.img_1 img {
  height: 634px;
  width: 1070px;
  margin-left:449px ;
  margin-top: -244px;
    top: 0; 
    left: 0;
    z-index: 0;
  
}
.effect img{
  height: 384px;
  width: 449px;
  margin-top: -12px;
  margin-left: 1063px;
}
.support-container {
  position: relative;
}

.icpatna,
.gtu,
.zohostartup,
.iim,
.metalogo,
.microsoftstartup,
.nvi,
.openai,
.ministry {
  position: absolute;
  transition: transform 0.6s;
}

@keyframes moveIcons {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(255px, 255px);
  }
 50% {
    transform: translate(-300px, 300px);
  }
  100%{
    transform:translate(0px,0px);
  }
}

.support-container:hover .icpatna {
  animation: moveIcons 5s linear infinite;
}

@keyframes moveIcons2 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(70px, 160px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.support-container:hover .metalogo {
  animation: moveIcons2 5s linear infinite;
}

@keyframes moveIcons4 {
  0% {
    transform: translate(2px,550px);
  }
  75% {
    transform: translate(300px, 150px);
  }
  100% {
    transform: translate(2px,550px);
  }
}

.support-container:hover .nvi {
  animation: moveIcons4 5s linear infinite;
}
.ministry-container{
  margin-left: 163px;
  margin-top:125px;
}

@keyframes moveIcons5 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-120px,350px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.support-container:hover .ministry {
  animation: moveIcons5 5s linear infinite;
}

@keyframes moveIcons6 {
  0% {
    transform: translate(2px,550px);
  }
  50% {
    transform: translate(-140px, -30px);
  }
  100% {
    transform: translate(2px,550px);
  }
}

.support-container:hover .zohostartup {
  animation: moveIcons6 5s linear infinite;
}

@keyframes moveIcons7 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-40px, 150px);
  }
  100% {
    transform: translate(0,0);
  }
}

.support-container:hover .iim {
  animation: moveIcons7 5s linear infinite;
}

@keyframes moveIcons8 {
  0% {
    transform: translate(-30px, 150px);
  }
  50% {
    transform: translate(250px, 400px);
  }
  100% {
    transform: translate(-30px, 150px);
  }
}

.support-container:hover .microsoftstartup {
  animation: moveIcons8 5s linear infinite;
}

@keyframes moveIcons9 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(350px, -450px);
  }
  80% {
    transform: translate(-150px, -400px);
  }
  100% {
    transform: translate(0,0);
  }
}

.support-container:hover .openai {
  animation: moveIcons9 5s linear infinite;
}

@keyframes moveGtu {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-70px, 280px);
  }
  50% {
    transform: translate(420px, 420px);
  }
  
  100% {
    transform: translate(0px, 0px); 
  }
}

.support-container:hover .gtu {
  animation: moveGtu 5s linear infinite;
}


.nvi-container{
  margin-left:-256px;
  margin-top:0px;
}
.zohostartup-container{
  margin-left:-100px;
  margin-top:0px;
}
.iim-container{
  margin-left:0px;
  margin-top:0px;
}
.blue-border-box-5 animation{
  margin-top:0px;
}

.last-email-container img{
  margin-left:0px;
  margin-top:0px;
}

/* TeamSection */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Exo;
}

.main-11{
  width: 100%;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007BFF;
  margin-top:127px;
}
.profile-card{
  position: relative;
  font-family: sans-serif;
  width: 220px;
  height: 220px;
  background: #fff;
  padding: 30px;
  display:fixed;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: .6s;
  margin: 0 66px;
}
.profile-card:hover{
  border-radius: 10px;
  height: 260px;
}
.profile-card .img{
  position: relative;
  width: 100%;
  height: 100%;
  transition: .6s;
  z-index: 99;
}
.profile-card:hover .img{
  transform: translateY(-60px);
}
.img img{
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: .6s;
}
.profile-card:hover img{
  border-radius: 10px;
}
.caption{
  text-align: center;
  transform: translateY(-80px);
  opacity: 0;
  transition: .6s;
}
.profile-card:hover .caption{
  opacity: 1;
}
.caption h3{
  font-size: 21px;
  font-family: sans-serif;
}
.caption p{
  font-size: 14px;
  color: #03070b;
  font-family: sans-serif;
  margin: 2px 0 9px 0;
}
.caption .social-links a{
  color: #333;
  margin-right: 15px;
  font-size: 21px;
  transition: .6s;
}
.social-links a:hover{
  color: #0c52a1;
}
input[type="email"]::placeholder {
  
  color: #faf7f7; 
  font-style: bold; 
  font-size: 15px;
  padding-left:0px ;
 margin-left:10px;
}
input[type="email2"]::placeholder {
  font-family:'poppins';
  color:#757575; 
  font-style: bold; 
  font-size: 21px;
  margin-left:50px;
 
}
input[type="email"] {
  border: 1px solid white;
 
}
.invalid{
  margin-left: 302px;
    margin-top: 12px;
}
.gui-container{
  margin-top:-100px;
}

/* About0 */
.about-us{
  font-size:22px;
  color:#007BFF;
  margin-left:118px;
  margin-top:118px;
}
.con1{
  height: 10px;
    width: 463px;
    font-size: 21px;
    font-weight: 600;
    font-family:'poppins';
    margin-left:117px;
    margin-top:19px;
}
.tainer1{
  width: 394.55px;
    height: 177px;
    margin-left: 117px;
    margin-top: 43px;
    font-size: 21px;
    font-weight: 500;
    color: #757575;
    line-height: 1.5;
}
.cloud-container img{
  margin-left:681px;
  margin-top:-354px;
}
.about-container{
  height:650px;
  width:100%;
}
.description-container{
  height:1174px;
}
.desc3{
    width: 525px;
    font-size: 21px;
    font-weight: 500;
    margin-left: 820px;
    color: #757575;
    margin-top:-472px;
}
.mission{
  color:#000;
  margin-left:96px;
}
.miss-desc1{
  width: 468.55px;
    height: 177px;
    margin-left: 104px;
    margin-top: 37px;
    font-size: 21px;
    font-weight: 500;
    color:#757575;
    line-height: 1.5;
}
.miss-desc2{
  width: 468.55px;
    height: 177px;
    margin-left: 104px;
    font-size: 21px;
    font-weight: 500;
    color:#757575;
    line-height: 1.5;
}
.img-des2 img{
  height:500px;
  width:578px;
  margin-left:170px;
}
.vision{
  color:#000;
  margin-left:211px;
  margin-top:174px;
}
.vis-desc{
  width: 468.55px;
    height: 177px;
    margin-left: 211px;
    margin-top: 37px;
    font-size: 21px;
    font-weight: 500;
    color:#757575;
    line-height: 1.5;
}
.img-des1 img{
  margin-top: -339px;
    margin-left: 907px;
}
.choose{
  color:#000;
  font-family:'poppins';
  font-weight:600;
  font-size: 32px;
  margin-left:650px;
  margin-top:0px;
  justify-content:center;
}
.person{
  font-size: 116px;
    margin-left: 212px;
    font-family:'poppins';
    margin-top:81px;
}
.son{
  font-size: 116px;
    margin-left: 266px;
    font-family:'poppins';
    margin-top:-133px;
    color:#007BFF;

}
.personalization{
  height: 10px;
  width: 370px;
  margin-left: 326px;
  margin-top: -108px;
  font-size: 23px;
  font-family:'poppins';

}
.person1{
  font-size: 116px;
    margin-left: 212px;
    font-family:'poppins';
    margin-top:81px;
}
.son1{
  font-size: 116px;
    margin-left: 266px;
    font-family:'poppins';
    margin-top:-133px;
    color:#007BFF;
}
.Convenience{
  height: 10px;
  width: 370px;
  margin-left: 326px;
  margin-top: -108px;
  font-size: 23px;
  font-family:'poppins';
}
.con-container{
  margin-top: 130px;
  margin-left: 619px;
}
.person{
  font-size: 116px;
    margin-left: 212px;
    font-family:'poppins';
    margin-top:81px;
}
.son{
  font-size: 116px;
    margin-left: 266px;
    font-family:'poppins';
    margin-top:-133px;
    color:#007BFF;
}
.personalization{
  height: 10px;
  width: 370px;
  margin-left: 326px;
  margin-top: -108px;
  font-size: 23px;
  font-family:'poppins';
}
.pers-container{
  margin-top: 130px;
    margin-left: -14px;
}

/* Contact */
.contactt-container img{
  height: 469px;
  width: 467px;
  margin-top: -308px;
  margin-left: 855px;
}
.contact-container{
    height:778px;
    width:100%; 
}
.get{
color:#000;
  margin-left:92px;
  margin-top:9px;
  }
  .get-desc{
    width: 468.55px;
      height: 70px;
      margin-left: 104px;
      margin-top: 9px;
      font-size: 21px;
      font-weight: 500;
      color:#757575;
      line-height: 1.5;
  }
  .contactt-us{
    font-size:22px;
    color:#007BFF;
    margin-left:94px;
  }
  .call-iconn img{
    
    height: 60px;
    width: 60px;
    margin-left: 99px;
    margin-top: 19px;
    border-radius: 50%;
    box-shadow: rgb(170, 170, 170) 2px 2px 4px 0px;
  }
  .desc4{
    height:300px;
    width: 525px;
    font-size: 21px;
    font-weight: 500;
    margin-left: 820px;
    color: #757575;
    margin-top:-472px;
}
.call-1{
  margin-left:186px;
  margin-top:-55px;
  color:#757575;
  font-size:17px;
}
.call-2{
  margin-left: 185px;
  margin-top:0px;
  color:#000;
  font-size:20px;
}
.maill-iconn img{
  height: 60px;
  width: 60px;
  margin-left: 99px;
  margin-top: 40px;
  border-radius: 50%;
  box-shadow: rgb(170, 170, 170) 2px 2px 4px 0px;
}
.mail-1{
  margin-left:186px;
  margin-top:-55px;
  color:#757575;
  font-size:17px;
}
.mail-2{
  margin-left: 185px;
  margin-top:0px;
  color:#000;
  font-size:20px;
}
.locationn-iconn img{
  height: 60px;
  width: 60px;
  margin-left: 99px;
  margin-top: 40px;
  border-radius: 50%;
  box-shadow: rgb(170, 170, 170) 2px 2px 4px 0px;
}
.loca-1{
  margin-left:186px;
  margin-top:-65px;
  color:#757575;
  font-size:17px;
}
.loca-2{
  margin-left: 185px;
  margin-top:0px;
  color:#000;
  font-size:20px;
}
.back-contact-container{
  margin-top:0px;

}
.back-contact-container img{
  height: 887px;
  width: 1518px;
  margin-top: -707px;
}
.contact-uss{
font-size:22px;
color:#007BFF;
margin-left:706px;
margin-top:325px;
}
.gett{
  color:#000;
    margin-left:630px;
    margin-top:9px;

    }
    .first-name{
    background-color: #FBFBFB;
    color: rgb(117 117 117);
    height:50px;
    width:490px;
    margin-left:265px;
    margin-top:27px;
    position:relative;
    border: 1px #757575;
    font-size: 20px;
    padding-left:20px;
    }
    .last-name{
      background-color: #FBFBFB;
     color: rgb(117 117 117);
     height:50px;
     width:490px;
     margin-left:21px;
     margin-top:27px;
     position:relative;
     border: 1px #757575;
     font-size: 20px;
     padding-left:20px;
     }
     
     
     input[type="text"]::placeholder {
    
      font-style: bold; 
      font-size: 20px;
      padding-left:0px ;
    padding-left:20px;
    }
    .phone-number{
      background-color: #FBFBFB;
     color: rgb(117 117 117);
     height:50px;
     width:490px;
     margin-left:265px;
     margin-top:27px;
     position:relative;
     border: 1px #757575;
     font-size: 20px;
     padding-left:20px;
     }
     
     .email-address{
      background-color: #FBFBFB;
     color: rgb(117 117 117);
     height:50px;
     width:490px;
     margin-left:21px;
     margin-top:27px;
     position:relative;
     border: 1px #757575;
     font-size: 20px;
     padding-left:20px;
     }
     input[type="tel"]::placeholder {
      font-style: bold; 
      /* font-size: 20px; */
    padding-left:20px;
    }
    input[type="emaill"]::placeholder {
      font-style: bold; 
      /* font-size: 20px; */
      padding-left:0px ;
    padding-left:20px;
    }
    .message {
      background-color: #FBFBFB;
      color: rgb(117, 117, 117);
      height: 190px;
      width: 1003px;
      margin-left: 263px;
      margin-top: 27px;
      position: relative; 
      border: 1px #757575; 
      font-style: bold;
      font-size: 20px;
      padding: 5px;
      padding-left:20px; 
      top: 0;
      left: 0;
    }
    
    .message::placeholder {
      font-style: bold;
      font-size: 20px;
      padding: 5px;
      padding-left:40px;
      position: absolute; 
      top: 0;
      left: 0; 
    }
    
    
    .submit{
      background-color: #007BFF;
      color: #fff;
      height: 51px;
      width: 123px;
      margin-left: 700px;
      margin-top: 27px;
      font-size:20px;
      border: 1px #757575;
     }
     .ready-containerrr {
      background-color: #007BFF;
      height: 235px;
      width: 1080px;
      margin-top: 82px;
      margin-left: 227px;
      border-radius: 30px;
  }

/* Career */
.car{
  font-size:22px;
  color:#007BFF;
  margin-left:118px;
  margin-top:118px;
}
.care{
  height: 10px;
    width: 422px;
    font-size: 21px;
    font-weight: 600;
    font-family:'poppins';
    margin-left:112px;
    margin-top:19px;
}
.carei{
  width: 394.55px;
    height: 177px;
    margin-left: 117px;
    margin-top: 84px;
    font-size: 21px;
    font-weight: 500;
    color: #757575;
    line-height: 1.5;
}
.careerr-container img{
  height: 469px;
    width: 467px;
    margin-top: -381px;
    margin-left: 781px
}
.vacan{
  height: 10px;
  width: 468px;
  font-size: 33px;
  font-weight: 600;
  font-family: 'poppins';
  margin-left: 546px;
  margin-top: 124px;
}
.career2-container{
  height:400px;
}
.job1-container {
  background-color: #007BFF;
  height: 235px;
  width: 1173px;
  margin-top: 82px;
  margin-left: 183px;
  border-radius: 8px;
  margin-top:151px;
}
.j-title{
  margin-left: 297px;
    margin-top: -158px;
    color: #fff;
    font-size:22px;
    font-weight:600;
}
.experience{
  margin-left: 569px;
  margin-top: -27px;
  color: #fff;
  font-size:22px;
    font-weight:600;
}
.position{
    margin-left: 848px;
    margin-top: -27px;
    color: #fff;
    font-size:22px;
      font-weight:600;
}
.apply{
  height: 51px;
    width: 138px;
    background-color: #0c52a1;
    margin-left: 1117px;
    margin-top: 0px;
    color: #fff;
    border-radius:10px;
    font-size:26px;
}
.senior{
  margin-left: 240px;
  margin-top: -22px;
  color: #fff;
  font-size: 19px;
}
.years{
  margin-left: 582px;
  margin-top: -22px;
  color: #fff;
  font-size: 19px;
}
.num-1{
  margin-left: 911px;
  margin-top: -22px;
  color: #fff;
  font-size: 19px;
}
.footer-containerr{
  background-color:#007BFF;
  height: 543px;
  width: 1519px;
  margin-top:1358px;
}
.navbar li a {
  color: white; 
  text-decoration: none; 
font-size:18px;

}

.navbar li a:hover {
  color: #007BFF; 
 
}
 .active-link {
font-size:18px;
color:#007BFF !important;
  background-color: #fff ;
border-radius:10px;
height: 52.8px;
  width: 109.99px;
}
.active-link > a {
  font-size: 18px;
  color: #007BFF !important; 
  background-color: #fff;
  border-radius: 10px;
  height: 52.8px;
  width: 109.99px;
}
.active-linkk {
  font-size:18px;
  color:#007BFF !important;
    background-color: #fff ; 
  border-radius:10px;
  height: 52.8px;
    width: 117.99px; 
  }
  .active-linkk > a {
    font-size: 18px;
    color: #007BFF !important; 
    background-color: #fff;
    border-radius: 10px;
    height: 52.8px;
    width: 117.99px;
  }
 

